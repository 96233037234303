<template>
  <div v-if="this.$store.state.sipActive" class="fixed bottom-10 left-8 z-50"><sip/></div>
  <router-view/>
</template>

<script>
import sip from '@/components/sip/sip.vue'
export default {
  components: {
    sip
  },
  mounted() {
    if (!this.isLoggedIn) {
      this.$store.state.sipActive = false
      this.$router.push({ name: 'login' });
    }else {
      this.$store.state.sipActive = true
    }
},
computed: {
  isLoggedIn() {
    return Boolean(localStorage.getItem('token'));
  },
},
}
</script>